/*
    Autor: Kátia miglioli
*/
import React, { Component } from 'react';
import DataTable from '@components/DataTable/DataTable';
import CardFilters from '@components/CardFilters/CardFilters';
import GroupButtonGrid from '@components/GroupButtonGrid/GroupButtonGrid';
import ButtonFAB from '@components/ButtonFAB/ButtonFAB';
import iconAdd from '@images/icone_add.png';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { flattenMessages } from '@utils/languageUtils';
import { IntlProvider } from 'react-intl';
import { getUserLang } from '@utils/localeUtils';
import ptBR from './i18n/ptBR';
import enUS from './i18n/enUS';
import esES from './i18n/esES';
import '@styles/Alerts.css';
import FilterUtil from '../../filterUtil';
import Loading from '@components/Loading/Loading';
import CircularProgress from '@material-ui/core/CircularProgress';
import swal from '@sweetalert/with-react';
import Usuario from '@resources/Usuario';

let messages = { 'pt-BR': ptBR, 'en-US': enUS, 'es-ES': esES };

var { provider } = new IntlProvider({});

const style = theme => ({
  fab: {
    position: 'relative',
    bottom: theme.spacing.unit * 9.5,
    right: theme.spacing.unit * 3,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '10%',
    zIndex: 1,
    height: '10%',
    marginBottom: 0,
    marginLeft: '90%'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00622B',
      main: '#00622B',
      dark: '#00622B',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

class Tecnicos extends Component {
  constructor(props) {
    super(props);

    const messagesLinguage = flattenMessages(messages[getUserLang()]);
    const descricao = messagesLinguage['tecnico.descricao'];
    const nome = messagesLinguage['tecnico.nome'];
    const email = messagesLinguage['tecnico.email'];
    const telefone = messagesLinguage['tecnico.telefone'];
    const situacao = messagesLinguage['tecnico.situacao'];
    const ativo = messagesLinguage['tecnico.ativo'];
    const inativo = messagesLinguage['tecnico.inativo'];
    const falhaAoExcluirTecnico = messagesLinguage['tecnico.falhaAoExcluirTecnico'];
    const tecnicoExcluidoComSucesso = messagesLinguage['tecnico.tecnicoExcluidoComSucesso'];
    const excluir = messagesLinguage['tecnico.excluir'];
    const excluindo = messagesLinguage['tecnico.excluindo'];
    const desejaExcluir = messagesLinguage['tecnico.desejaExcluir'];
    const nao = messagesLinguage['tecnico.nao'];

    const intlProvider = new IntlProvider(
      {
        locale: getUserLang(),
        messages: {
          descricao,
          nome,
          email,
          telefone,
          situacao,
          ativo,
          inativo,
          nao,
          desejaExcluir,
          excluindo,
          excluir,
          falhaAoExcluirTecnico,
          tecnicoExcluidoComSucesso
        }
      },
      {}
    );

    provider = intlProvider.getChildContext();

    this.state = {
      itemSelect: '',
      disabledEdit: true,
      key: '',
      dataInTable: [],
      dataAppSync: [],
      statusAlert: false,
      loading: true,
      head: [
        {
          field: 'nmUsuario',
          headerName: provider.intl.formatMessage({ id: 'nome' }),
          sort: 'asc',
          col: 4
        },
        {
          field: 'nrTelefone',
          headerName: provider.intl.formatMessage({ id: 'telefone' }),
          col: 2
        },
        {
          field: 'dsEmail',
          headerName: provider.intl.formatMessage({ id: 'email' }),
          col: 4
        },
        {
          field: 'nmStUsuario',
          headerName: provider.intl.formatMessage({ id: 'situacao' }),
          col: 2
        }
      ],
      operationValues: [
        {
          label: provider.intl.formatMessage({ id: 'nome' }),
          field: 'nmUsuario',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'telefone' }),
          field: 'nrTelefone',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'email' }),
          field: 'dsEmail',
          type: 'string'
        },
        {
          label: provider.intl.formatMessage({ id: 'situacao' }),
          field: 'stUsuario',
          type: 'enum',
          options: [
            {
              label: provider.intl.formatMessage({ id: 'ativo' }),
              field: 'ativo',
              value: '1'
            },
            {
              label: provider.intl.formatMessage({ id: 'inativo' }),
              field: 'inativo',
              value: '0'
            }
          ]
        }
      ]
    };

    const title = 'tecnico.tecnico';
    if (this.props.itensState.name !== 'tecnico.tecnico') {
      this.props.dispatch({
        type: 'UPDATE_TOOLBAR',
        toolbar: (
          <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
            <FormattedMessage id={title} />
          </IntlProvider>
        ),
        name: title
      });
    }

    this.selectChange = this.selectChange.bind(this);
    this.clickAdicionar = this.clickAdicionar.bind(this);
    this.clickEditar = this.clickEditar.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.clickExcluir = this.clickExcluir.bind(this);
    this.atualizarRegistros = this.atualizarRegistros.bind(this);
  }

  selectChange(select) {
    if (select) {
      this.setState({
        itemSelect: select,
        disabledEdit: false
      });
    } else {
      this.setState({
        itemSelect: '',
        disabledEdit: true
      });
    }
  }

  clickAdicionar() {
    this.props.history.push('/app/tecnicos/novo');
  }
  clickEditar() {
    this.props.history.push('/app/tecnicos/editar/' + this.state.itemSelect.idUsuario);
  }
  componentDidMount() {
    this.onFilter(this.props.chipData);
  }
  onFilterChanged(dataChips) {
    this.setState({
      dataInTable: FilterUtil.applyFilters(this.state.dataAppSync, dataChips)
    });
  }
  onFilter(dataChips) {
    dataChips = dataChips || [];
    Usuario.findAllUsuarios()
      .then(query => {
        let data = [];
        query.data.forEach(doc => {
          if (doc.tpPerfil === 2) {
            doc.nmStUsuario =
              doc.stUsuario === 1
                ? provider.intl.formatMessage({ id: 'ativo' })
                : provider.intl.formatMessage({ id: 'inativo' });

            data.push(doc);
          }
        });

        this.setState({
          dataAppSync: data,
          dataInTable: FilterUtil.applyFilters(data, dataChips),
          loading: false
        });
      })
      .catch(err => {
        this.setState({
          dataAppSync: [],
          dataInTable: [],
          loading: false
        });
      });
  }
  tratarSituacao(dataTecnicos) {
    var data = 0;
    for (data in dataTecnicos) {
      dataTecnicos[data].nmStUsuario =
        dataTecnicos[data].stUsuario === 1
          ? provider.intl.formatMessage({ id: 'ativo' })
          : provider.intl.formatMessage({ id: 'inativo' });
    }
  }

  clickExcluir() {
    swal({
      title: provider.intl.formatMessage({ id: 'desejaExcluir' }),
      icon: 'warning',
      closeOnClickOutside: false,
      closeOnEsc: true,
      buttons: {
        cancel: {
          text: provider.intl.formatMessage({ id: 'nao' }),
          value: false,
          visible: true,
          closeModal: true,
          className: 'swal2-cancel'
        },
        confirm: {
          text: provider.intl.formatMessage({ id: 'excluir' }),
          value: true,
          visible: true,
          closeModal: false,
          className: 'swal2-confirm'
        }
      }
    }).then(willDelete => {
      if (willDelete) {
        swal.stopLoading();
        swal(
          <div>
            <MuiThemeProvider theme={theme}>
              <CircularProgress />
            </MuiThemeProvider>
          </div>,
          {
            buttons: false,
            closeOnEsc: false,
            closeOnClickOutside: false,
            title: provider.intl.formatMessage({ id: 'excluindo' })
          }
        );

        Usuario.deleteUsuario(this.state.itemSelect.idUsuario)
          .then(result => {
            swal(provider.intl.formatMessage({ id: 'tecnicoExcluidoComSucesso' }), {
              icon: 'success',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-Ok'
                }
              }
            }).then(() => {
              this.atualizarRegistros(this.state.itemSelect.idUsuario);
            });
          })
          .catch(error => {
            swal(provider.intl.formatMessage({ id: 'falhaAoExcluirTecnico' }), {
              icon: 'error',
              buttons: {
                confirm: {
                  text: 'Ok',
                  value: true,
                  visible: true,
                  closeModal: true,
                  className: 'swal2-error'
                }
              }
            });
          });
      }
    });
  }

  atualizarRegistros(dataUsuario) {
    var ajusteRegistros = this.state.dataAppSync.filter(data => {
      return dataUsuario !== data.idUsuario;
    });

    this.setState({
      dataAppSync: ajusteRegistros,
      dataInTable: ajusteRegistros
    });

    this.onFilterChanged(this.props.chipData);
  }

  render() {
    const pagination = {
      length: this.state.dataInTable.length,
      page: 0
    };
    return (
      <IntlProvider locale={getUserLang()} messages={flattenMessages(messages[getUserLang()])}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'scroll'
          }}
        >
          {this.state.loading && <Loading />}
          <CardFilters
            filtros={this.state.operationValues}
            onFilter={this.onFilter}
            onFilterChanged={this.onFilterChanged}
            usePaddings={false}
            viewKey='tecnicos'
          />
          <GroupButtonGrid
            showAdd={false}
            showEdit={true}
            showDelete={true}
            disableEdit={this.state.disabledEdit}
            disableDelete={this.state.disabledEdit}
            onClickEdit={this.clickEditar}
            onClickDelete={this.clickExcluir}
          />

          <div
            style={{
              marginTop: 30,
              marginBottom: 15,
              display: 'contents',
              width: '100%',
              height: '100%'
            }}
          >
            <DataTable
              data={this.state.dataInTable}
              columns={this.state.head}
              selectChange={this.selectChange}
              showPagination={true}
              pagination={pagination}
              key={this.state.key}
            />
          </div>

          <ButtonFAB icon={iconAdd} positionDefault={true} onClick={() => this.clickAdicionar()} />
        </div>
      </IntlProvider>
    );
  }
}
const mapStateToProps = state => ({
  itensState: state.toolbar,
  infoUsuario: state.adicionarInfoUsuario.info,
  chipData: state.chips.chipData.filter(date => {
    return date.id === 'tecnicos';
  })[0].content
});

let enhaced = withStyles(style)(Tecnicos);
export default withRouter(connect(mapStateToProps)(enhaced));
