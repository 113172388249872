export default {
  tecnico: {
    tecnico: "Technician",
    nome: "Name",
    email: "Email",
    telefone: "Telephone",
    situacao: "Situation",
    ativo: "Active",
    inativo: "Inactive",
    campoObrigatorio: "Required field",
    identificacao: "Identification",
    cadastro: "Technician Registration",
    salvar: "Save",
    cancelar: "Cancel",
    emailInvalido: "Invalid e-mail",
    naoFoiPossivelInserir: "Could not insert technician",
    salvando: "Saving",
    atualizando: "Updating",
    cadastradoComSucesso: "Successfully enrolled technician",
    editadoComSucesso: "Successfully edited technician",
    falhaAoCarregar: "Failed to load technical",
    naoFoiPossivelEditar: "Could not edit technician",
    emailDuplicado: "This email is already in use",
    numeroMaximoTecnicos: "Your plan technician limit has been reached",
    falhaAoExcluirTecnico: "Failed to delete technician",
    tecnicoExcluidoComSucesso: "Successfully deleted technician",
    excluir: "Delete",
    excluindo: "Deleting",
    desejaExcluir: "Do you want to delete?",
    nao: "Not",
    telefoneInvalido: "Invalid phone number"
  }
};
